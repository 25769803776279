body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.business{
  background-image: linear-gradient(to right bottom, #1d1c55, #5974d9, #232e57, #778ad1);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
  margin: 0px 10px;
}

.overlay::before{
  display: none !important;
}
.overlay::after{
  display: none !important;
}